import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ReactModalAdapter from '../ReactModalAdapter'
import { Button, Card, Loading } from '..'

interface Props<T extends object = {}> {
  title: string
  initialFormData?: T
  form?: React.FC<{ value: T; onChange: (value: T) => void }>
  children: (open: () => void) => React.ReactNode
  onConfirm: (data: T | undefined) => Promise<void> | void
}

const StyledModal = styled(ReactModalAdapter)`
  &__Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    transition: opacity 200ms ease-in-out;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 30rem;
    outline: none;
    -webkit-overflow-scrolling: touch;
    transform: translateY(-4rem);
    transition: transform 200ms ease-in-out;

    &--after-open {
      transform: translateY(0);
    }

    &--before-close {
      transform: translateY(-4rem);
    }
  }
`

const StyledCard = styled(Card)`
  text-align: center;
  padding: 1.5rem;
  border: none;

  & .content {
    position: relative;
  }
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`

const FormWrapper = styled.div`
  margin-top: 1rem;
`

const StyledButton = styled(Button)`
  margin: 0 0.5rem;
`

function ConfirmationDialog<T extends object = {}>({
  title,
  children,
  onConfirm,
  form: Form,
  initialFormData,
}: Props<T>) {
  const [isOpened, setOpened] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [formData, setFormData] = useState(initialFormData)

  useEffect(() => {
    if (isOpened) {
      setFormData(initialFormData)
      setLoading(false)
    }
  }, [isOpened, initialFormData])

  return (
    <>
      {children(() => setOpened(true))}
      <StyledModal
        isOpen={isOpened}
        closeTimeoutMS={200}
        onRequestClose={() => setOpened(false)}
        ariaHideApp={false} // FIXME should be removed
      >
        <StyledCard contentClassName="content">
          {title}
          {formData && Form && (
            <FormWrapper>
              <Form value={formData} onChange={setFormData} />
            </FormWrapper>
          )}
          <ButtonsWrapper>
            <StyledButton onClick={() => setOpened(false)}>Cancel</StyledButton>
            <StyledButton
              onClick={async () => {
                setLoading(true)
                await onConfirm(formData)
                setOpened(false)
              }}
            >
              Confirm
            </StyledButton>
          </ButtonsWrapper>
          {isLoading && (
            <LoadingWrapper>
              <Loading delay={0} />
            </LoadingWrapper>
          )}
        </StyledCard>
      </StyledModal>
    </>
  )
}

export default ConfirmationDialog
