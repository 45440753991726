import React, { useRef } from 'react'
import ReactSelect, { Props as SelectProps } from 'react-select'
import AsyncSelect, { Props as AsyncSelectProps } from 'react-select/async'
import styled, { useTheme } from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const noOperation = () => {}

function Select<T = { label: string; value: string }>({
  isRequired,
  isDisabled,
  className = '',
  value,
  ...props
}: ((AsyncSelectProps<T> & { async: true }) | (SelectProps<T> & { async?: false })) & { isRequired?: boolean }) {
  const theme = useTheme()
  const selectRef = useRef<any>(null)

  const setFocus = () => {
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }

  const commonProps = {
    styles: {
      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
      control: (base: any) => ({
        ...base,
        border: `1px solid ${theme.colors.mediumGray2};`,
        borderRadius: '0.5rem',
        minHeight: '2.5rem',
      }),
    },
    menuPortalTarget: document.body,
    isDisabled,
  }

  const select = props.async ? (
    <AsyncSelect<T> {...props} {...commonProps} ref={selectRef} />
  ) : (
    <ReactSelect<T> {...props} {...commonProps} ref={selectRef} />
  )

  return (
    <Wrapper className={className}>
      {select}
      {isRequired && !isDisabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            opacity: 0,
            width: '100%',
            height: 1,
          }}
          value={value ? '.' : ''}
          onChange={noOperation}
          onFocus={setFocus}
          required
        />
      )}
    </Wrapper>
  )
}

export default Select
