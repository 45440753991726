import React from 'react'
import styled from 'styled-components'
import { ExitToApp } from '@material-ui/icons'
import { NavLink, Link } from 'react-router-dom'

import logo from './logo.svg'
import loading from './loading.svg'

interface Props {
  children: React.ReactNode
  header?: {
    title?: string
    environment?: string
  }
  menu?: {
    category?: string
    links: {
      label: string
      icon: React.ComponentType<{ fill: string }>
      to: string
    }[]
  }[]
  user?: {
    avatar?: string
    name?: string
  }
  logout?: () => void
  isLoading?: boolean
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 3.75rem;
  width: 100%;
  background: ${(p) => p.theme.gradients.brand};
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-style: italic;
`

const Logo = styled.img`
  width: 5.75rem;
  margin-right: 2rem;
`

const Loader = styled.img<{ isLoading: boolean }>`
  margin-left: 1rem;
  opacity: ${(p) => (p.isLoading ? '1' : '0')};
  transition: opacity 100ms ease-in;
`

const Title = styled.span`
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.75rem;
`
const EnvironmentWrapper = styled.div`
  height: 2rem;
  display: flex;
  align-items: start;
`

const Environment = styled.span`
  font-size: 0.875rem;
  text-transform: uppercase;
`

const User = styled.div`
  display: flex;
  align-items: center;
  color: white;
`

const Avatar = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid white;
`

const Username = styled.div`
  font-weight: bold;
  margin: 0 0.5rem;
  font-size: ${(p) => p.theme.fontSizes.normal};
`

const Logout = styled.button`
  background: none;
  outline: none;
`

const Menu = styled.div`
  position: fixed;
  top: 3.75rem;
  left: 0;
  width: 12.5rem;
  height: calc(100% - 3.75rem);
  background-color: ${(p) => p.theme.colors.darkBlack};
  overflow-y: scroll;
  padding: 2.5rem 0;
`

const MenuCategory = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }
`

const CategoryLabel = styled.div`
  color: ${(p) => p.theme.colors.darkGray2};
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
`

const MenuLink = styled(NavLink).attrs({
  activeClassName: 'active-nav-link',
})`
  position: relative;
  height: 2rem;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.mediumGray2};
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;

  &:hover {
    color: ${(p) => p.theme.colors.primary};
    font-weight: bold;
  }

  &.active-nav-link {
    color: ${(p) => p.theme.colors.primary};
    font-weight: bold;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      background-color: ${(p) => p.theme.colors.primary};
    }
  }
`

const LinkLabel = styled.div`
  margin: 0 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Content = styled.div<{ withMenu: boolean }>`
  padding: 4.75rem 1rem 1rem ${(p) => (p.withMenu ? '13.5rem' : '1rem')};
  height: 100%;
  overflow-y: auto;
`

const Layout = ({ children, header, menu, user, logout, isLoading = false }: Props) => {
  return (
    <Wrapper>
      <Content withMenu={!!menu}>{children}</Content>
      <Header>
        <TitleWrapper>
          <Link to="/">
            <Logo src={logo} alt="" />
          </Link>
          {header?.title && <Title>{header.title}</Title>}
          {header?.environment && (
            <EnvironmentWrapper>
              <Environment>{header.environment}</Environment>
            </EnvironmentWrapper>
          )}
          <Loader src={loading} alt="" isLoading={isLoading} />
        </TitleWrapper>
        {user && (
          <User>
            <Avatar src={user.avatar} />
            <Username>{user.name}</Username>
            {logout && (
              <Logout onClick={logout}>
                <ExitToApp fill="currentColor" />
              </Logout>
            )}
          </User>
        )}
      </Header>
      {menu && (
        <Menu>
          {menu.map(({ category, links }, index) => (
            <MenuCategory key={index}>
              {category && <CategoryLabel>{category}</CategoryLabel>}
              {links.map(({ to, icon: Icon, label }, index) => (
                <MenuLink to={to} key={index}>
                  <Icon fill="currentColor" />
                  <LinkLabel>{label}</LinkLabel>
                </MenuLink>
              ))}
            </MenuCategory>
          ))}
        </Menu>
      )}
    </Wrapper>
  )
}

export default Layout
