import React from 'react'
import styled from 'styled-components'

import { ConfirmationDialog, Button } from '..'
import { HeaderProps } from '../types'

interface Props {
  children: React.ReactNode
  header?: HeaderProps
  className?: string
  contentClassName?: string
  headerClassName?: string
}

const Header = styled.div`
  height: 3.375rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  background-color: ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  color: ${(p) => p.theme.colors.white};
`

const HeaderAction = styled(Button)`
  background: none;
  border: 2px solid white;
  margin-left: 0.5rem;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active:enabled {
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const HeaderContent = styled.div`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Content = styled.div<{ hasHeader: boolean }>`
  padding: 0.75rem;
  overflow: hidden;
  background: white;
  border: 1px solid ${(p) => p.theme.colors.lightGray};
  ${(p) =>
    p.hasHeader
      ? `
    border-top: none;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;`
      : `
    border-radius: 0.5rem;
      `}
`

const Card = ({ children, header, className = '', contentClassName = '', headerClassName = '' }: Props) => (
  <div className={className}>
    {header && (
      <Header className={headerClassName}>
        <HeaderContent>{header.title}</HeaderContent>
        {header.actions?.map(({ label, onClick, confirmation }, index) =>
          !!confirmation ? (
            <ConfirmationDialog
              key={index + label}
              title={confirmation.title}
              initialFormData={confirmation.initialData}
              form={confirmation.form}
              onConfirm={onClick}
            >
              {(open) => (
                <HeaderAction small onClick={open}>
                  {label}
                </HeaderAction>
              )}
            </ConfirmationDialog>
          ) : (
            <HeaderAction key={index + label} onClick={onClick} small>
              {label}
            </HeaderAction>
          )
        )}
      </Header>
    )}
    <Content hasHeader={!!header} className={contentClassName}>
      {children}
    </Content>
  </div>
)

export default Card
