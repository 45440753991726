import React, { forwardRef } from 'react'
import styled from 'styled-components'

interface Props {
  icon?: React.ComponentType<any>
  className?: string
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${(p) => p.theme.colors.mediumGray2};
  overflow: hidden;
  background-color: white;
`

const IconWrapper = styled.div`
  margin-left: 0.5rem;
`

const StyledInput = styled.input`
  width: 100%;
  padding: 0 0.5rem;

  &:focus {
    outline: none;
  }
`

const Input = forwardRef(
  (
    { icon: Icon, className = '', as, ...props }: Props & React.HTMLProps<HTMLInputElement>,
    ref?: React.Ref<HTMLInputElement>
  ) => (
    <Wrapper className={className}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      <StyledInput {...props} ref={ref} />
    </Wrapper>
  )
)

export default Input
