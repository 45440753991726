import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode
  className?: string
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: no-wrap;
  max-width: 100%;

  & *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }
  & *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const InputGroup = ({ children, className }: Props) => <Wrapper className={className}>{children}</Wrapper>

export default InputGroup
