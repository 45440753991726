import React from 'react'
import styled from 'styled-components'

const Table = styled.table`
  width: 100%;
`

const Row = styled.tr`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(p) => p.theme.colors.lightGray};
  }
`

const Cell = styled.td`
  padding: 0.5rem 0;
  width: 100%;
`

const LabelCell = styled.td`
  padding: 0.5rem 1.5rem 0.5rem 0;
  white-space: nowrap;
  font-weight: bold;
`

interface Props {
  items: {
    label: string
    value?: any
  }[]
  className?: string
}

const List = ({ items, className = '' }: Props) => (
  <Table className={className}>
    <tbody>
      {items.map(({ label, value }, index) => (
        <Row key={index}>
          <LabelCell>{label}</LabelCell>
          <Cell>{value}</Cell>
        </Row>
      ))}
    </tbody>
  </Table>
)

export default List
