import React from 'react'
import styled from 'styled-components'
import { ArrowBackOutlined, ArrowForwardOutlined } from '@material-ui/icons'

import InputGroup from '../InputGroup'
import Button from '../Button'

interface Props {
  onPrevious?: () => any
  onNext?: () => any
  className?: string
}

const StyledPagination = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const Pagination = ({ onPrevious, onNext, className }: Props) => (
  <StyledPagination className={className}>
    <InputGroup>
      <Button onClick={onPrevious} disabled={!onPrevious}>
        <ArrowBackOutlined />
      </Button>
      <Button onClick={onNext} disabled={!onNext}>
        <ArrowForwardOutlined />
      </Button>
    </InputGroup>
  </StyledPagination>
)

export default Pagination
