import { createGlobalStyle } from 'styled-components'
import 'normalize.css'

import centuryGothic from './centuryGothic.ttf'
import centuryGothicBold from './centuryGothicBold.ttf'

export default createGlobalStyle`
@font-face {
  font-family: CenturyGothic;
  src: url(${centuryGothic});
}

@font-face {
  font-family: CenturyGothic;
  src: url(${centuryGothicBold});
  font-weight: bold;
}


html {
  font-family: CenturyGothic;
  font-size: 16px;
  color: #41464F;
  line-height: 1.5;
  height: 100%;
}

body, #root  {
  height: 100%;
  font-size: 0.875rem;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #a0aec0;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #a0aec0;
}

input::placeholder,
textarea::placeholder {
  color: #a0aec0;
}

button,
[role='button'] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

.ReactModal__Body--open {
  overflow: hidden;
}`
