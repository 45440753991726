import React from 'react'
import styled from 'styled-components'

import TodoItem from './TodoItem'

interface Props {
  todos?: Todo[][][]
  userAuthorizations: string[]
  withAutoScroll?: boolean
  validate: (id: string) => any
}

const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  padding: 1.5rem 0 1rem;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  &:first-child {
    &:before {
      content: '';
      height: 1.5rem;
      width: 0.125rem;
      position: absolute;
      top: -1.5rem;
      background: ${(p) => p.theme.colors.mediumGray3};
      left: 50%;
    }
  }

  &:last-child {
    &:after {
      content: '';
      height: 1.5rem;
      width: 0.125rem;
      position: absolute;
      bottom: -1.5rem;
      background: ${(p) => p.theme.colors.mediumGray3};
      left: 50%;
    }
  }
`

const Column = styled.div`
  &:first-of-type {
    margin-left: auto;
  }
  &:last-of-type {
    margin-right: auto;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24rem;
  flex: 0 0 25%;
  &:first-child {
    &:before {
      content: '';
      width: 55%;
      position: absolute;
      top: 0;
      height: 0.125rem;
      background: ${(p) => p.theme.colors.mediumGray3};
      left: 50%;
    }

    &:after {
      content: '';
      width: 55%;
      position: absolute;
      height: 0.125rem;
      background: ${(p) => p.theme.colors.mediumGray3};
      bottom: 0;
      left: 50%;
    }
  }

  &:before {
    content: '';
    width: 100%;
    position: absolute;
    height: 0.125rem;
    background: ${(p) => p.theme.colors.mediumGray3};
  }

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 0.125rem;
    background: ${(p) => p.theme.colors.mediumGray3};
  }

  &:last-child {
    &:before {
      content: '';
      width: 50%;
      position: absolute;
      height: 0.125rem;
      background: ${(p) => p.theme.colors.mediumGray3};
      right: 50%;
    }
    &:after {
      content: '';
      width: 50%;
      position: absolute;
      bottom: 0;
      height: 0.125rem;
      background: ${(p) => p.theme.colors.mediumGray3};
      right: 50%;
    }
  }

  &:only-child {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
`

const TodoLink = styled.div`
  position: absolute;
  height: 100%;
  width: 0.125rem;
  background: ${(p) => p.theme.colors.mediumGray3};
  top: 0;
  left: 50%;
  z-index: 0;
`

const TodosList = ({ todos, userAuthorizations, withAutoScroll, validate }: Props) => {
  return (
    <LayoutWrapper>
      {todos &&
        todos.map((row: Todo[][], index: number) => (
          <Row key={index}>
            {row.map((column: Todo[], secondIndex: number) => (
              <Column key={secondIndex + 100}>
                <TodoLink />
                {column.map((todo: Todo) => (
                  <TodoItem
                    key={todo.id}
                    todo={todo}
                    userAuthorizations={userAuthorizations}
                    withAutoScroll={withAutoScroll}
                    validate={validate}
                  />
                ))}
              </Column>
            ))}
          </Row>
        ))}
    </LayoutWrapper>
  )
}

export default TodosList
