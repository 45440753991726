import React, { useState, useRef, useEffect } from 'react'
import { ArrowBackOutlined } from '@material-ui/icons'
import styled from 'styled-components'

import ReactModalAdapter from '../ReactModalAdapter'
import { Button, ConfirmationDialog, HeaderProps } from '..'

interface PanelProps {
  children: React.ReactNode
  header: HeaderProps
  onClose: () => void
  className: string
  contentClassName: string
}

interface Props {
  children: (setHeader: ({ title, actions }: HeaderProps) => void) => React.ReactNode
  onClose: () => void
  isOpened: boolean
  className?: string
  contentClassName?: string
}

const StyledModal = styled(ReactModalAdapter)`
  &__Overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    transition: opacity 300ms ease-in-out;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  &__Content {
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: 0;
    height: 100%;
    background: ${(p) => p.theme.colors.white};
    -webkit-overflow-scrolling: touch;
    outline: none;
    margin-left: 5rem;
    max-width: min(70rem, calc(100% - 5rem));
    min-width: min(calc(100% - 5rem), 70rem);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;

    &--after-open {
      transform: translateX(0);
    }

    &--before-close {
      transform: translateX(100%);
    }
  }
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div<{ isScrolled: boolean }>`
  display: flex;
  align-items: center;
  color: white;
  flex: 0 0 3.75rem;
  background: ${(p) => p.theme.colors.primary};
  font-weight: 500;
  font-size: 1.125rem;
  padding: 1rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  ${(p) => (p.isScrolled ? 'box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.25);' : '')}
`

const HeaderAction = styled(Button)`
  background: none;
  border: 2px solid white;
  margin-left: 0.5rem;
  white-space: nowrap;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:active:enabled {
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const BackButton = styled.button`
  margin-right: 1rem;
  background: none;
  border: none;
  padding: 0;
`

const HeaderContent = styled.div`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Content = styled.div`
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
`

const PanelContent = ({ header, onClose, children, className, contentClassName }: PanelProps) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const contentEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const content = contentEl.current
    if (content) {
      const onScroll: EventListener = () => {
        if (content.scrollTop && content.scrollTop >= 0) setIsScrolled(true)
        else setIsScrolled(false)
      }

      content.addEventListener('scroll', onScroll)
      return () => content?.removeEventListener('scroll', onScroll)
    }
    return
  }, [])

  return (
    <Wrapper className={className}>
      <Header isScrolled={isScrolled}>
        <BackButton onClick={onClose}>
          <ArrowBackOutlined />
        </BackButton>
        <HeaderContent>{header.title}</HeaderContent>
        {header.actions?.map(({ label, onClick, confirmation }, index) =>
          !!confirmation ? (
            <ConfirmationDialog
              key={index + label}
              title={confirmation.title}
              initialFormData={confirmation.initialData}
              form={confirmation.form}
              onConfirm={onClick}
            >
              {(open) => (
                <HeaderAction small onClick={open}>
                  {label}
                </HeaderAction>
              )}
            </ConfirmationDialog>
          ) : (
            <HeaderAction key={index + label} onClick={onClick} small>
              {label}
            </HeaderAction>
          )
        )}
      </Header>
      <Content className={contentClassName} ref={contentEl}>
        {children}
      </Content>
    </Wrapper>
  )
}

const Panel = ({ children, isOpened, onClose, className = '', contentClassName = '' }: Props) => {
  const [header, setHeader] = useState<HeaderProps>({})

  return (
    <StyledModal
      isOpen={isOpened}
      onRequestClose={onClose}
      onAfterClose={() => setHeader({})}
      closeTimeoutMS={300}
      ariaHideApp={false} // FIXME should be removed
    >
      <PanelContent header={header} onClose={onClose} className={className} contentClassName={contentClassName}>
        {children(setHeader)}
      </PanelContent>
    </StyledModal>
  )
}

export default Panel
