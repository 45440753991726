import React from 'react'
import Modal from 'react-modal'

const ReactModalAdapter = ({
  className,
  ...props
}: ReactModal.Props & { className?: string; children: React.ReactNode }) => {
  return (
    <Modal
      portalClassName={className}
      className={{
        base: `${className}__Content`,
        afterOpen: `${className}__Content--after-open`,
        beforeClose: `${className}__Content--before-close`,
      }}
      overlayClassName={{
        base: `${className}__Overlay`,
        afterOpen: `${className}__Overlay--after-open`,
        beforeClose: `${className}__Overlay--before-close`,
      }}
      {...props}
    />
  )
}

export default ReactModalAdapter
