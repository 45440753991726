import React from 'react'
import styled from 'styled-components'
import { useDropzone, FileWithPath } from 'react-dropzone'

const Wrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 5rem;
  border-radius: 0.5rem;
  border: 1px dashed ${(p) => p.theme.colors.mediumGray2};
  overflow: hidden;
  background-color: ${(p) => (p.isActive ? p.theme.colors.lightGray2 : p.theme.colors.white)};
  outline: none;
`

interface Props {
  multiple?: boolean
  accept?: string
  onDrop: (files: FileWithPath[]) => void
  children?: React.ReactNode
}

const Dropzone = ({ onDrop, multiple = false, children, accept }: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Wrapper isActive={isDragActive} {...getRootProps()}>
      <input {...getInputProps()} multiple={multiple} accept={accept} />
      {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop or click to select files</p>}
      {children}
    </Wrapper>
  )
}

export default Dropzone
