import React from 'react'
import styled from 'styled-components'

interface Props {
  small?: boolean
}

const StyledButton = styled.button<Props>`
  height: ${(p) => (p.small ? '2rem' : '2.5rem')};
  font-size: ${(p) => (p.small ? p.theme.fontSizes.small : p.theme.fontSizes.normal)};
  border-radius: 0.5rem;
  background-color: ${(p) => p.theme.colors.primary};
  color: #fff;
  user-select: none;
  outline: none;

  &:hover {
    background-color: #ea603f;
  }

  &:active:enabled {
    background-color: #e95330;
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors.lightGray};
  }
`

const Wrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 0 ${(p) => (p.small ? '0.75rem' : '1.25rem')};
`

const Button = ({
  children,
  small,
  type = 'button',
  onClick,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <StyledButton {...props} small={small} onClick={onClick} type={type}>
    <Wrapper small={small}>{children}</Wrapper>
  </StyledButton>
)

export default Button
