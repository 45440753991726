import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import loader from './loader.svg'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  delay?: number
}

const Loading = ({ delay = 400 }: Props) => {
  const [isDisplayed, setDisplay] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), delay)
    return () => clearTimeout(timeout)
  }, [delay])

  return isDisplayed ? (
    <Wrapper>
      <img src={loader} alt="Loading..." />
    </Wrapper>
  ) : null
}

export default Loading
