export default {
  gradients: {
    brand: 'linear-gradient(90deg, #8426AB 0%, #EB7D61 100%)',
    primary: 'linear-gradient(1.13deg, #EB4467 1.1%, #EE7D5E 99.19%)',
    secondary: 'linear-gradient(181.3deg, #1529A3 6.82%, #7C25AC 92.91%)',
  },
  fontSizes: {
    heading1: '3.5rem',
    heading2: '3rem',
    heading3: '2.5rem',
    heading4: '2rem',
    heading5: '1.5rem',
    heading6: '1.25rem',
    large: '1.25rem',
    medium: '1.125rem',
    normal: '1rem',
    small: '0.875rem',
  },
  lineHeights: {
    title: '1.1',
    body: '1.4',
  },
  colors: {
    primary: '#EE795E',
    secondary: '#8C27AE',
    secondary2: '#6040B0',
    info: '#0F29A2',
    success: '#68DBC3',
    warning: '#F5CA45',
    error: '#D63964',
    darkBlack: '#000000',
    mediumBlack: '#1D1D1D',
    lightBlack: '#222B3A',
    darkGray: '#2E3033',
    darkGray2: '#41464F',
    mediumGray: '#717782',
    mediumGray2: '#AEB3BD',
    mediumGray3: '#d2d2d2',
    lightGray: '#E7E8EA',
    lightGray2: '#F9F9F9',
    white: '#FFFFFF',
  },
}
