import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Fingerprint, Description, Group, FileCopy } from '@material-ui/icons'

import { Button, ConfirmationDialog } from '../..'

interface Props {
  todo: Todo
  userAuthorizations: string[]
  withAutoScroll?: boolean
  validate: (id: string) => any
}

const TodoCard = styled.button<{ isToggled: boolean; status: string }>`
  position: relative;
  scroll-margin: 4.125rem;
  width: 20.875rem;
  min-height: 3.875rem;
  border-radius: ${(p) => (p.isToggled ? '0.125rem 0.125rem 0 0' : '0.125rem')};
  box-shadow: 0px 1px 3px ${(p) => p.theme.colors.mediumGray};
  padding: 1rem;
  margin: 1rem 1rem 0 1rem;
  font-size: 0.75rem;
  background-color: ${(p) => p.theme.colors.white};
  &:last-child {
    margin-bottom: 2rem;
  }
  &:focus {
    outline: none;
  }

  &:before {
    content: '';
    position: absolute;
    height: 70%;
    width: 0.25rem;
    top: 15%;
    left: 0;
    background-color: ${(p) => {
      if (p.status === 'done') {
        return (p) => p.theme.colors.success
      } else if (p.status === 'wip') {
        return (p) => p.theme.colors.primary
      } else {
        return (p) => p.theme.colors.error
      }
    }};
  }
`
const TodoItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TeamTag = styled.div`
  background-color: ${(p) => p.theme.colors.secondary};
  color: ${(p) => p.theme.colors.white};
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 0.75rem;
  padding: 0.25rem 0.5rem;
  margin-left: 0.125rem;
`

const TeamSpan = styled.span`
  margin-left: 0.25rem;
  text-transform: capitalize;
`

const InfosWrapper = styled.div`
  max-width: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.span`
  font-weight: bold;
  text-align: center;
`

const ValidationDate = styled.span`
  font-style: italic;
  font-size: 0.687rem;
`

const TriangleIcon = styled.div<{ isToggled: boolean }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${(p) => p.theme.colors.darkBlack};
  transition: transform 300ms ease-in-out;
  transform: ${(p) => p.isToggled && 'rotate(0.5turn)'};
`

const HiddenInfosWrapper = styled.div<{ isToggled: boolean }>`
  z-index: 0;
  width: 20.875rem;
  background-color: ${(p) => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  margin-bottom: ${(p) => (p.isToggled ? '2rem' : '0.125rem')};
  border-radius: ${(p) => (p.isToggled ? '0 0 0.125rem 0.125rem' : '0')};
  padding: 0.875rem;
  opacity: ${(p) => (p.isToggled ? '1' : '0')};
  max-height: ${(p) => (p.isToggled ? '100%' : '0')};
  box-shadow: 0 1px 0.125rem 0 ${(p) => p.theme.colors.mediumGray};
  overflow: hidden;
  transition: max-height 0.1s, opacity 0.1s, margin 0.1s ease-in;
`

const TeamMembersWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DescriptionSpan = styled.span`
  margin-left: 0.25rem;
  width: 85%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const IdWrapper = styled.div`
  display: flex;
  align-items: flex;
  margin-bottom: 0.5rem;
`

const Id = styled.span`
  width: 100%;
`
const ClipboardButton = styled.button`
  background-color: inherit;
  margin-left: 0.5rem;
  &:focus {
    outline: none;
  }
`

const CopiedMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
`

const TodoItem = ({
  todo: { authKey, status, title, id, description, validatedAt, team },
  userAuthorizations,
  withAutoScroll,
  validate,
}: Props) => {
  const [isToggled, setIsToggled] = useState<boolean>(false)
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const todoCardRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (status === 'wip') {
      setIsToggled(true)
    }
  }, [status, setIsToggled])

  useEffect(() => {
    if (status === 'wip' && withAutoScroll) {
      todoCardRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [status, withAutoScroll])

  return (
    <>
      <TodoCard isToggled={isToggled} status={status} ref={todoCardRef} onClick={() => setIsToggled(!isToggled)}>
        <TodoItemContainer>
          <TeamTag>{team ?? 'No Team'}</TeamTag>

          <InfosWrapper>
            <Title>{title}</Title>
            <ValidationDate>{validatedAt !== '0001-01-01T00:00:00Z' && validatedAt}</ValidationDate>
          </InfosWrapper>

          <TriangleIcon isToggled={isToggled} />
        </TodoItemContainer>
      </TodoCard>
      <HiddenInfosWrapper isToggled={isToggled}>
        <TeamMembersWrapper>
          <Group fontSize="small" />
          <TeamSpan>{team}</TeamSpan>
        </TeamMembersWrapper>
        <IdWrapper>
          <Fingerprint fontSize="small" />
          <Id>{id}</Id>
        </IdWrapper>
        {description && (
          <>
            <DescriptionWrapper>
              <Description fontSize="small" />
              <DescriptionSpan>{description}</DescriptionSpan>
              <ClipboardButton
                onClick={() => {
                  setIsCopied(true)
                  navigator.clipboard.writeText(description)
                  setTimeout(() => setIsCopied(false), 3000)
                }}
              >
                <FileCopy fontSize="small" />
              </ClipboardButton>
            </DescriptionWrapper>
            {isCopied && (
              <CopiedMessageWrapper>
                <span>Copied to clipboard !</span>
              </CopiedMessageWrapper>
            )}
          </>
        )}
        {userAuthorizations.includes(authKey) && status === 'wip' && (
          <ButtonWrapper>
            <ConfirmationDialog
              title={`Are you sure you want to validate task : ${title} ?`}
              onConfirm={async () => await validate(id)}
            >
              {(open) => <Button onClick={open}>Validate</Button>}
            </ConfirmationDialog>
          </ButtonWrapper>
        )}
      </HiddenInfosWrapper>
    </>
  )
}

export default TodoItem
