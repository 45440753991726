import React, { useRef } from 'react'
import styled from 'styled-components'
import { useTable, Column, Row } from 'react-table'

import { Card, Pagination, HeaderProps } from '..'

const StyledWrapper = styled(Card).attrs(() => ({ contentClassName: 'content ' }))`
  & > .content {
    font-size: ${(p) => p.theme.fontSizes.small};
    line-height: 1;
    width: 100%;
    overflow: auto;
    padding: 0;
  }
`
const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
`
const StyledTableHeader = styled.th`
  color: ${(p) => p.theme.colors.mediumGray};
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  padding: 1rem;
`
const StyledRow = styled.tr`
  cursor: pointer;
  &:nth-child(odd) {
    background-color: ${(p) => p.theme.colors.lightGray2};
  }
  &:hover {
    background-color: ${(p) => p.theme.colors.lightGray};
  }
`
const StyledCell = styled.td`
  padding: 0.75rem 1rem;
  white-space: nowrap;
  border-top: 1px solid ${(p) => p.theme.colors.lightGray};
  overflow-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface Props<T extends object = {}> {
  columns: Column<T>[]
  hiddenColumns?: string[]
  header?: HeaderProps
  data: T[]
  onRowClick?: (row: Row<T>) => void
  loading?: boolean // TODO handle loading state
  withPagination?: boolean
  onPrevious?: () => any
  onNext?: () => any
}

function Table<T extends object = {}>({
  columns,
  header,
  data,
  onRowClick,
  withPagination,
  onPrevious,
  hiddenColumns = [],
  onNext,
}: Props<T>) {
  const columnsRef = useRef(columns)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<T>({
    columns: columnsRef.current,
    data,
    initialState: { hiddenColumns },
  })

  return (
    <>
      <StyledWrapper header={header}>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <StyledTableHeader {...column.getHeaderProps()} key={index}>
                    {column.render('Header')}
                  </StyledTableHeader>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <StyledRow {...(onRowClick && { onClick: () => onRowClick(row) })} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <StyledCell {...cell.getCellProps()} key={index}>
                        {cell.render('Cell')}
                      </StyledCell>
                    )
                  })}
                </StyledRow>
              )
            })}
          </tbody>
        </StyledTable>
      </StyledWrapper>
      {withPagination && <Pagination onPrevious={onPrevious} onNext={onNext} />}
    </>
  )
}

export default Table
